/* You can add global styles to this file, and also import other style files */

$primary: rgb(10, 91, 138);
$border-radius: 0.1rem;
$border-radius-sm: $border-radius;
$modal-content-border-radius: $border-radius;
// $enable-rounded: false;

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.16.8/katex.min.css";

html,
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}

.navbar {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  font-size: 14px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.fk-busy {
  position: relative;
}

.fk-busy:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.fk-busy {
  // hide the text
  color: rgba(0, 0, 0, 0) !important;
}

.login-body {
  background: #0d7bbc; //linear-gradient(180deg, #525d72 0%, #69768d 100%);
  color: white;
  min-height: 100vh;
  a {
    color: #c3ccdd;
  }
  .login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .login-panel {
      // width: 30%;
      height: 100%;
      text-align: center;
      padding: 40px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .login-form {
        width: 350px;
        margin: 1rem 0;
      }
      .logo img {
        height: 155px;
      }
    }
  }
  label {
    color: #000;
  }
  .form-control-top {
    margin-bottom: -1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: black;
  }

  .form-control-inside {
    margin-bottom: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: black;
  }

  .form-control-bottom {
    margin-bottom: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    color: black;
  }

  .form-control-only {
    margin-bottom: 10px;
    color: black;
  }
}

.breadcrumb {
  background: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  font-size: larger;
  padding: 8px 15px;
  padding-left: 0;
  margin-bottom: 20px;
}

/** Arch Dropzone Styling */
.arch-dropzone {
  border: 2px dashed gray;
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 0.375rem;
  position: relative;
  border-radius: 3px;
  text-align: center;
  input[type="file"] {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    width: 100%;
  }
}

.arch-dropzone-hovering {
  border-style: solid;
}

.arch-heading {
  margin-bottom: 0;
  flex: 1;
  // white-space: nowrap;
  > * {
    margin-bottom: 0;
  }

  .arch-sub-heading {
    color: #aaa;
    font-size: 60%;
    font-style: italic;
    display: block;
    font-weight: normal;
    margin: 0 0 0.25em 0;
  }
  margin-bottom: 0;
}

.nav-tabs .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  padding: 0.5rem 0.25rem;
  margin: 0 1rem 0 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
  border-bottom: 3px solid #dee2e6;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none;
  border-bottom: 3px solid #0d6efd;
  background-color: inherit;
}

// .ck-editor__editable_inline {
//   min-height: 500px;
// }

/*
 * Configure the z-index of the editor UI, so when inside a Bootstrap
 * modal, it will be rendered over the modal.
 */
:root {
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

.ck-body-wrapper {
  position: relative;
  z-index: 5000000;
}

.block-label .form-label {
  display: block;
}

.question-choice {
  border-color: transparent !important;
}
.question-choice.question-choice-selected {
  border-color: green !important;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

.d-block-label {
  .form-label {
    display: block;
  }
  .form-text {
    display: block;
  }
}

.table-middled td {
  vertical-align: middle;
  > div.d-flex {
    align-items: center;
  }
}

.one-column {
  column-count: 1 !important;
}

.column-list {
  column-count: 1;
  list-style-type: none;
  padding: 0;
  li {
    padding: 0.25rem 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    margin-bottom: 0.5rem;
    display: block;
    // &:hover {
    //   background-color: #6c757d;
    //   color: white;
    // }
    &.nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (min-width: 200px) {
  .column-list {
    column-count: 1;
  }
}

@media (min-width: 850px) {
  .column-list {
    column-count: 2;
  }
}

@media (min-width: 1021px) {
  .column-list {
    column-count: 3;
  }
}
